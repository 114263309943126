// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as farFaBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes as farFaTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

import { faFacebookSquare as fabFaFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faInstagram as fabFaInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faLinkedin as fabFaLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faYoutube as fabFaYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faTwitter as fabFaTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faPinterest as fabFaPinterest } from "@fortawesome/free-brands-svg-icons/faPinterest";

/**
 * Add regular icons
 */
library.add(farFaBars, farFaTimes);

/**
 * Add brands icons
 */
library.add(fabFaFacebookSquare, fabFaInstagram, fabFaYoutube, fabFaTwitter, fabFaLinkedin, fabFaPinterest);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
